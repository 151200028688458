@import "styles/_variables.scss";

.app-sidebar {
  width: 15rem;
  z-index: 10;
  background: #F3F8FB;

  .sidebar-nav {
    height: 100%;
    padding: 1.25rem 0.875rem;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style-type: none;
      flex-wrap: nowrap;
      flex-direction: column;
      height: 100%;
      position: relative;
      li {
        a, button {
          padding: .625rem .75rem;
          display: flex;
          align-items: center;
          transition: ease .2s color;
          position: relative;
          outline: none;
          margin: 0.25rem 0;
          border-radius: 0.375rem;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1rem;
          color: $text-color-lighter;
          width: 100%;
          justify-content: space-between;

          &.active {
            background: #E0EDF5;
            color: $theme-color-blue;

            svg {
              color: $theme-color-blue;
            }
          }

          .menu-link-title {
            svg {
              margin-right: .75rem;
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }
}