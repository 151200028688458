.modal-content {
  box-shadow: 0 2px 6px rgba(0, 102, 164, 0.12);
  border-radius: 0.5rem;
  .modal-header {
    padding: 1.625rem 1.875rem;
  }
  .modal-title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $caption-color;
  }
  .btn-close {
    background-size: 0.625rem;
  }
  .modal-body {
    padding-top: 1.563rem;
    padding-right: 1.875rem;
    padding-bottom: 0.625rem;
    padding-left: 1.875rem;
    p {
      font-size: 0.813rem;
      line-height: 1.313rem;
      color: $text-color;
      margin-bottom: 0.625rem;
      &.description {
        font-weight: 400;
        font-size: 0.875rem;
        &.text-start {
          margin-bottom: 1.25rem;
          color: $text-color;
        }
      }
    }
    li {
      font-size: 0.813rem;
      line-height: 1.313rem;
      color: $text-color;
    }
    .form-wrap {
      form {
        margin-top: 0;
      }
    }

    .radio-wrap {
      label:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .modal-footer {
    border-top: none;
    padding-top: 0;
    padding-right: 1.875rem;
    padding-bottom: 1.625rem;
    padding-left: 1.875rem;
    .btn {
      border: none;
      box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
    }
  }
}

.modal-footer > * {
  margin: 0;
}

.job-seeker-invitation-modal {
  .qr-code {
    padding: 0.625rem;
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 32.5rem;
    &.detail-modal {
      max-width: 50rem;
    }
  }
}
