.offcanvas {
  box-shadow: 0 2px 6px rgba(0, 102, 164, 0.12);
  border-radius: 0.5rem;
  .offcanvas-header {
    padding: 1.625rem 1.875rem;
    border-bottom: 1px solid #d5dee7;
  }
  .offcanvas-title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $caption-color;
  }
  .btn-close {
    background-size: 0.625rem;
  }
}