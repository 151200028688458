body {
  &.modal-open {
    overflow: hidden;
    position: static;
  }
}

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .app-content {
    flex: 1;
  }
}

.container-fluid {
  padding-right: 2.25rem;
  padding-left: 2.25rem;

  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

a {
  color: $theme-color-blue;
  &:hover {
    color: $light-blue;
  }
}

a, a:hover, a:visited, a:active {
  text-decoration: none;
  cursor: pointer;
}

.bold {
  font-weight: 600 !important;
}

.bolder {
  font-weight: 700;
}

.logo-wrap {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 2.25rem;
}

.hidden {
  display: none;
}

.checked-icon-wrap {
  text-align: center;
  width: 5rem;
  height: 5rem;
  padding: 0.875rem;
  background: #E0FAEC;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 1.625rem;
  margin-left: auto;
  border-radius: 50%;
  svg {
    width: 3.25rem;
    height: 3.25rem;
  }
}

.right {
  float: right;
}

.round-border {
  border-radius: 0.375rem;
}

.light-gray-bg {
  background: $light-background-color;
}

.box-shadow {
  box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
}

.toggle-sidebar {
  display: none;
}

.full-width {
  width: 100%;
}

.underline {
  text-decoration: underline;
}

.text-with-linebreaks {
  white-space: pre-wrap;
}

.flex  {
  display: flex;
  .domain {
    font-size: .875rem;
    color: #304C67;
  }
}

@include media-breakpoint-down(lg) {
  form {
    .row:not(.tabs):first-of-type {
      width: calc(100% + 1.5rem);
    }
  }
  .margin-bottom-md-4 {
    margin-bottom: 1.5rem;
  }
}

@include media-breakpoint-down(md) {
  html {
    font-size: 0.875rem;
  }
  .toggle-sidebar {
    display: list-item;
  }
}