
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before{
  border: none;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border: none;
}

.tooltip-inner {
  background-color: #072440;
  border-radius: 0.375rem;
  box-shadow: 0px 2px 6px rgba(0, 102, 164, 0.12);
  max-width: 19.625rem;
  padding: 0.625rem 0.75rem;
  text-align: left;
}

.tooltip {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding-left: 0.625rem;
}

.tooltip-wrap {
  background: $input-color-light;
  border-radius: 50%;
  padding: 0 0.25rem;
  color: #fff;
  margin-left: 0.313rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  &:hover {
    background: $theme-color-blue;
  }
}