@import "styles/_variables.scss";

.app-footer {
  padding: 1.063rem 0;
  color: $caption-color;
  background: $light-background-color;
  bottom: 0;
  width: 100%;
  .container-fluid {
    display: flex;
    justify-content: space-between;
    .footer-top-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: .5625rem;

      .logo {
        .svg-icon {
          width: 3.9375rem;
          height: auto;
          
          .fill {
            fill: #99A1A8;
          }
        }
      }
    }

    .copyright, 
    .app-version {
      font-size: .875rem;

      p {
        margin: 0;
        padding: 0;
        font-size: 0.688rem;
        line-height: 0.813rem;
        color: $text-color-lighter;
      }
    }

    .website-links {
      display: flex;
      justify-content: space-between;
      .website-link, a {
        margin: 0;
        font-size: 0.688rem;
        line-height: 0.813rem;
        color: $text-color-lighter;
      }

      .website-link:not(:last-of-type) {
        margin-right: 1.75rem;
      }
    }

    @include media-breakpoint-down(sm) {
      .website-links {
        flex-direction: column;
  
        .website-link {
          margin-bottom: .5rem;
        }
      }
    }
  }
}