@import "styles/_variables.scss";

.nav-collapse {
  .collapse-wrap {
    padding: .75rem 0;

    .nav-collapse-link {
      margin: 0;
      padding: .5rem calc(.75rem + 1.25rem + .75rem);
      font-size: .75rem;
      background: url('../../../public/svg/Line.svg') no-repeat calc(.75rem + 1.25rem / 2 - .25rem / 2) 50%;
      background-size: contain;

      &.active {
        background: url('../../../public/svg/Selected.svg') no-repeat calc(.75rem + 1.25rem / 2 - .25rem / 2) 50%;
        background-size: contain;
      }
    }
  }

  .toggle-btn.active {
    .chevron {
      rotate: 180deg;
    }
  }
}
