@import 'styles/variables';

.app-loading {
  text-align: center;
  position: absolute;
  top: 50%;
  top: calc(50% - 3rem);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
