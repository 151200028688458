@import "styles/_variables.scss";

.programs-count {
  background: $light-background-color;
  border-radius: 0.25rem;
  padding: 0.313rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: $text-color-lighter;
  cursor: pointer;
  svg {
    margin-bottom: 0.125rem;
    width: 0.75rem;
    height: 0.75rem;
  }
  &:hover {
    background: #E0EDF5;
    color: #1177B5;
    svg {
      path {
        fill: #1177B5;
      }
    }
  }
}

.assigned-programs-tooltip {
  .tooltip-inner {
    background: #FFFFFF;
    padding: 0;
    .loaded {
      border-radius: 0.375rem;
      border: 1px solid $border-color;
      padding: 0.625rem 0.75rem;
    }
  }
  .job-seeker-name, .title {
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 0.75rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $text-color-lighter;
    margin-bottom: 0.5rem;
  }
  .program {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $text-color;
    margin-bottom: 0.313rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .dot {
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: $text-color-lighter;
  }
}