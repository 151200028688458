@import "styles/_variables.scss";

.validation-wrap {
  ul {
    list-style: none;
    background: $light-background-color;
    border-radius: 0.375rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 1.25rem;

    li {
      font-weight: $font-weight-medium;
      font-size: 0.688rem;
      color: $text-color-lighter;

      &::before {
        content: "\2022";
        line-height: 1.6;
        font-weight: $font-weight-bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      &.green {
        color: $theme-color-green;
        font-weight: $font-weight-bold;
      }
    }

  }
}