.btn {
  border-radius: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.75rem;
  border: none;
  &.btn-primary {
    background: $theme-color-blue;
    color: #FFF;
    &:hover {
      background: $light-blue;
    }
    &:disabled {
      opacity: 0.36;
    }
  }

  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(.btn-sm) {
      min-height: $input-height;
    }

    &.btn-small {
      padding: 0 0.313rem;
      min-height: 1.5rem;
    }

    .svg-icon {
      display: block;

      & + span {
        margin-left: .5rem;
      }
    }

    svg {
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;
    }

    &.icon-right {
      svg {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }

    &.no-text {
      background-color: transparent;
      padding: 0;

      &:hover {
        background: transparent;
      }
    }
  }

  &.btn-secondary {
    background: $light-background-color;
    color: $text-color-lighter;
    margin-right: 0.5rem;
    &:hover {
      background: $light-gray;
      color: $light-blue;
    }
    &:disabled {
      background: rgba(243, 248, 251, 0.84);
      color: rgba(112, 143, 174, 0.36);
    }
  }

  &.btn-small {
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding: 0.313rem;
    border-radius: 0.25rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &.btn-outline-primary {
    color: $theme-color-blue;
    border: 1px solid $theme-color-blue;

    &:hover {
      background: $theme-color-blue;
      color: #FFF;
    }
  }
}

.page-wrap .grid-data .btn-small {
  svg {
    margin-right: 0.25rem;
  }
  &.icon-right {
    svg {
      margin-right: 0;
      margin-left: 0.25rem;
    }
  }
}

.btn-check:focus + .btn, .btn:focus, .btn-close:focus {
  outline: 0 !important;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08) !important;
  &.no-text {
    box-shadow: none !important;
  }
}

.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08) !important;
}

.form-wrap {
  .btn.back-btn {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: $theme-color-blue;
    box-shadow: none;
    padding-left: 0;

    &:hover {
      color: $light-blue;
    }
    svg {
      margin-right: 0.25rem;
      margin-top: -0.125rem;
    }
  }
}

.btn-white {
  box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  background: #FFFFFF;
  color: $theme-color-blue;
  &:hover {
    color: $theme-color-blue;
  }
}

.reset-button-style {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

button.link {
  color: $theme-color-blue;
  font-weight: inherit;
  text-align: left;
  &:hover {
    color: $light-blue;
  }
}
