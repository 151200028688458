@import 'src/styles/variables';

.navbar-header {
  &.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  max-height: 4rem;
  border-bottom: 1px solid #dee6ed;
  background: $header-bg;
  height: 5rem;
  box-shadow: 0px 2px 6px rgba(0, 102, 164, 0.12);
  z-index: 15;

  .navbar-toggler {
    margin-right: 0.5rem;
    background: transparent;
    border: none;

    &:focus {
      box-shadow: none;
    }

    svg {
      color: $theme-color-blue;
      width: 1.875rem;
      height: 1.875rem;
    }
  }

  .navbar-brand {
    line-height: 1;
    margin-right: 0.5rem;
    padding: 0;

    img,
    svg {
      height: 1.875rem;
      width: auto;
    }
  }

  .navbar-nav {
    flex-direction: row;
    .nav-item {
      .organization-name {
        font-weight: 600;
        font-size: 0.813rem;
        line-height: 1rem;
        color: $caption-color;
      }

      .navbar-username {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.938rem;
        color: $text-color;
      }

      .organization-name,
      .navbar-username {
        max-width: 20rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      @include media-breakpoint-down(md) {
        .organization-name,
        .navbar-username {
          max-width: 10rem;
        }
      }

      @include media-breakpoint-down(sm) {
        .organization-name,
        .navbar-username {
          max-width: 5rem;
        }
      }

      .btn.account-switch {
        margin-right: 1rem;

        &.btn-link {
          color: $theme-color-blue;

          &:hover {
            color: $light-blue;
          }
        }
        &.active {
          color: $theme-color-blue;
          background-color: #e0edf5;
        }
      }

      .btn.btn-link {
        color: $theme-color-blue;
        text-decoration: none;

        &:hover {
          color: $light-blue;
        }
      }
    }
  }

  .dropdown,
  .dropdown.show {
    .btn-secondary {
      color: $input-color-light;
      background-color: #fff;
      border: none;
      outline: 0;
      outline-color: transparent;
      &:hover {
        background-color: #fff;
      }
    }

    &:focus-visible {
      outline: 0;
    }

    > .btn-secondary {
      &.dropdown-toggle:focus {
        box-shadow: none;
      }
    }

    .btn {
      padding: 0;
      margin-right: 0;
      padding-right: 0;
      display: flex;
      text-align: left;

      &:focus {
        box-shadow: none !important;
      }

      svg {
        vertical-align: top;
        margin-top: 0.125rem;
        margin-left: 0.5rem;
      }
    }

    .btn-user-profile {
      display: flex;
    }

    .menu-item.signout-item {
      display: flex;
      justify-content: space-between;
      svg {
        color: $input-color-light;
      }
    }

    .user-profile {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      & svg.user-icon {
        margin: 0 10px 0 0;
      }
      @media (max-width: 767px) {
        & svg.user-icon {
          margin: 0;
        }
      }
    }

    @media (max-width: 767px) {
      .btn-user-profile,
      svg.dropdown-arrow {
        display: none !important;
      }
    }
  }

  .dropdown-menu {
    min-width: 11.688rem;
    padding: 0.407rem 0;
    border-radius: 0.375rem;
    top: 0.9rem !important;
  }

  .container-fluid {
    padding-right: 1.85rem;
    padding-left: 1.85rem;
  }

  @include media-breakpoint-up(md) {
    max-height: 4.063rem;
  }

  .btn.btn-invite-job-seekers {
    margin: 0 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #0066a4;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    min-height: 2.25rem !important;
    @media (max-width: 767px) {
      margin: 2px 1.2rem 0 1.2rem;
    }
  }
}

.dropdown {
  .dropdown-item {
    font-weight: 400;
    padding: 0.625rem 0.6875rem;
    color: $text-color;
    font-size: 0.8125rem;
    line-height: 1rem;
    a {
      color: $text-color;
      &:hover {
        color: $text-color;
      }
    }
    &:hover,
    &:focus,
    &.active,
    &:active {
      color: $text-color;
      background-color: #f3f8fb;
    }
  }
}
