@import "react-toastify/dist/ReactToastify.css";

.Toastify__toast-container {
  min-height: 2.5rem;
  padding: 0;
  width: initial;
  max-width: 28.75rem;
  min-width: 28.75rem;

  .Toastify__toast {
    text-align: center;
    width: 100%;
    background: #FFF;
    border: .0625rem solid $border-color;
    min-height: auto;
    border-radius: $border-radius;
    box-shadow: $container-box-shadow;
    padding: 0.9rem;

    .Toastify__toast-body {
      color: $titles-color;
      display: flex;
      align-items: center;
      font-size: .8125rem;
      text-align: left;
      font-weight: $font-weight-medium;
      padding: 0;

      .Toastify__toast-icon {
        padding-right: 1rem;
        width: auto;
        margin-right: 0;
        max-width: 3.25rem;
      }

      .svg-icon {
        height: auto;
        width: auto;
        margin-right: 0;
      }

      .toast-data {
        display: flex;
        justify-content: space-between;

        .toast-header {
          padding: 0;
          color: $caption-color;
          font-weight: 600;
          border-bottom: none;
          line-height: 0.983rem;
        }

        .toast-body {
          padding: 0;
          margin-top: 0.5rem;
          font-weight: 400;
          color: $text-color-lighter;
          line-height: 0.908rem;
        }

        .toast-close {
          align-self: baseline;
          margin-left: 1rem;
        }
      }
    }

    &--success {
      border-color: $theme-color-green;

      .Toastify__toast-body {
        .svg-icon {
          color: $theme-color-green;
        }
      }
    }
  
    &--error {
      border-color: $red;

      .Toastify__toast-body {
        .svg-icon {
          color: $red;
        }
      }
    }
  }

  &-body {
    padding-right: .75rem;
  }
}

.Toastify__close-button {
  align-self: auto;
  font-weight: normal;
  outline: none;
  padding: 0 0 0 1.625rem;

  > svg {
    display: block;
    color: $theme-color-gray;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  .Toastify__toast-container {
    max-width: 90%;
    min-width: 90%;
  }
}
