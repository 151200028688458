@import "styles/_variables.scss";

.rd__container .clear-indicator-container {
  color: $input-color-light;
  cursor: pointer;
  padding: 0;

  svg {
    width: .75rem;
    height: .75rem;
  }

  &:hover,
  &:focus {
    svg {
      color: $theme-color-blue;
    }
  }
}
