@import 'src/styles/variables';

.rd__container {
  .rd__placeholder, [class$='-placeholder'] {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
  }

  .rd__control {
    width: 100%;
    font-weight: $font-weight-normal;
    border-color: $border-color;
    min-height: auto;
    box-shadow: 0 0.125rem 0.125rem rgba(0, 102, 164, 0.08);
    min-height: 2.5rem;
    border-radius: 0.375rem;
    

    .rd__value-container {
      padding: $input-padding-y $input-padding-x;
      padding-right: 0;

      .rd__single-value {
        color: $text-color;
        font-size: 0.813rem;
        line-height: 1.25rem;
      }

      .rd__input {
        text-indent: 0;
      }

      &--is-multi {
        .rd__multi-value {
          color: $theme-color-blue;
          background-color: $light-gray;
          border-radius: 0.375rem;
          width: auto;
          display: inline-flex;
          max-height: 1.5rem;
          align-items: center;
          max-width: 100%;

          &__label {
            width: 100%;
            color: $theme-color-blue;
            font-size: 0.75rem;
            line-height: 1.1rem;
            padding: 0.2rem 0.15rem 0.2rem 0.5rem;
          }

          &__remove {
            background-color: $light-gray;
            color: $input-color-light;
            cursor: pointer;
            padding: 0.25rem 0.25rem;
            border-radius: 0.375rem;

            &:hover,
            &:focus {
              color: $theme-color-blue;
            }
          }

          & + .rd__multi-value {
            margin-top: 0.25rem;
          }
        }
      }
    }

    .rd__indicators {
      .rd__indicator {
        color: $input-color-light;

        &.rd__dropdown-indicator {
          padding: 0 0.5rem;

          svg {
            display: block;
            margin-right: 0;
            color: $input-color-light;

            .stroke {
              stroke: currentColor;
            }
          }
        }
      }
    }

    &:hover {
      border-color: $input-border-color;
    }

    &.rd__control--is-focused, .rd__control--menu-is-open {
      border-color: $theme-color-blue;

      .rd__value-container {
        .rd__multi-value {
          & + [class$='Input'] {
            position: static;
            margin-left: 0.5rem;
          }
        }
      }
    }

    &.rd__control--is-disabled {
      background: $input-disabled-bg;
    }
  }

  &.is-invalid {
    .rd__control {
      border-color: $theme-color-red;
    }
  }

  .rd__menu {
    font-size: 0.813rem;
    line-height: 1.25rem;
    color: $text-color;
    z-index: 2;

    .rd__menu-list {
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;

      .rd__option {
        padding: 0.35rem 0.6875rem;
        font-weight: 400;
        cursor: pointer;

        &.rd__option--is-selected {
          background: #fff;
          color: $theme-color-blue;
          font-weight: 600;
        }

        &.rd__option--is-focused,
        &:active {
          background: $light-background-color;
        }
      }
    }
  }

  &.rd__big {
    .rd__control {
      border-width: 0.125rem;
      min-width: 10rem;
      min-height: 2.8125rem;

      .rd__value-container {
        padding-top: 0.782rem;
        padding-bottom: 0.782rem;

        .rd__single-value {
          font-size: 0.9375rem;
        }
      }
    }

    .rd__menu {
      font-size: 0.9375rem;
    }
  }

  &.rd__scrollable {
    .rd__control {
      .rd__value-container {
        padding-top: 0;
        padding-bottom: 0;

        > [data-simplebar='init'] {
          max-width: 42rem;
          min-width: 7rem;
          overflow-y: hidden;
          overflow-x: auto;
          width: 100% !important;

          @include media-breakpoint-down(lg) {
            max-width: 30rem;
          }

          @include media-breakpoint-down(md) {
            max-width: 27.5rem;
          }

          @include media-breakpoint-down(sm) {
            max-width: 100%;
          }

          .filter-item-control & {
            width: 100% !important;
            max-width: 100%;
          }
        }

        .rd__multi-value {
          & + [class$='-Input'] {
            display: inline-flex;
            width: auto;
          }
        }

        &.rd__value-container--is-multi {
          padding-top: 0.125rem;
          padding-right: 0.35rem;
          padding-bottom: 0.125rem;
        }

        .simplebar-track {
          bottom: 0;

          .simplebar-scrollbar {
            &::before {
              background: $theme-color-blue;
            }
          }
        }
      }
    }

    &.rd--is-disabled {
      .rd__value-container {
        .simplebar-track {
          display: none;
        }
      }
    }
  }
}
