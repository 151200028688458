@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$theme-color-blue: #0066A4;
$theme-color-green: #1EB161;
$theme-color-gray: #A2B8CD;
$theme-color-red: #FF564F;

$border-color: #D5DEE7;
$text-color: #304C67;
$light-gray: #E8F2F8;
$input-color-light: #90A8C0;
$text-color-lighter: #708FAE;
$caption-color: #14293E;
$caption-darker-color: #7E94A9;
$titles-color: #3B3B3B;
$light-blue: #1177B5;
$light-background-color: #F3F8FB;
$container-box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .04);
$buttons-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .04);

$font-weight-medium: 500;
$font-weight-bold: 600;

$header-bg: #FFF;
$header-box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .04);

$sidebar-bg: #1F355E;
$sidebar-width: 5rem;
$sidebar-item-color: #A2B8CD;
$sidebar-item-hover-color: $theme-color-green;
$sidebar-item-active-bg: $theme-color-blue;
$sidebar-item-active-color: $theme-color-green;

$tooltip-opacity: 1;

$offcanvas-horizontal-width: 600px;
$offcanvas-border-width: 0;
